

.valid-form{
    display: block;
    padding: 50px;
    height: 600px;
    max-width: 900px;
    margin: auto;
}
.valid-form form{
    margin-top: 20px;
}
.valid-form p{
    color:brown;
    display: block;
    font-weight: 500;
    padding: 5px;
    margin-bottom: 24px;
}
.valid-form input,
.valid-form .phone-number input{
border: 1px solid black;
padding: 5px;
display: block;
background-color: white;
font-size: 15px;
font-weight: 400;
border-radius: 18px !important;
color: black;
}
.phone-number{
    width: 100%;
    display:flex;
    border: none;
    margin: 0;
    padding: 0;
}
.phone-number span{
    color: #007bff;
}
.phone-number input{
    width: 85% !important;
    margin: 0px;
}
  
  .valid-form {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  input, select {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 18px;
    color: black;
    height: 45px;
    
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
  }


  .databtn{
    display: block;
    background-color: #007bff;
    margin-left: 60px !important;
    margin-top: 10px !important;
    width: 160px !important;
    border-radius: 20px;

  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Optional: Add more styling for the select dropdown if needed */
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 30px;
    color: white;
    background-color: black;
    background: url('https://cdn.iconscout.com/icon/free/png-256/free-down-arrow-16-460295.png') no-repeat center right;
    background-size: 20px;
    border-radius: 18px !important;
    
  }
  
  .notvalid{
    color: red;
  }

  .input-group select {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333; /* Text color */
    background-color: #fff; /* Background color */
    cursor: pointer;
    width: 85% !important;

  }
  
  .input-group select option {
    padding: 10px;
    color: #333; /* Text color */
    background-color: #fff; /* Background color */
    
  }
  
  .input-group select option:hover {
    background-color: #f5f5f5; /* Hover background color */
  }
  
  label{
    padding: 0;
    margin: 0;
    display: block !important;
    margin-top: 10px;
    color: white;

  }
 .input-group{
    display: block !important;
 }
  .input-group :disabled{
    width: 85% !important;
    display: block !important;

  }

  .valid-form form{
    margin-left: 0px;
  }

  .airtimeOptions {
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    background-color: #a24a4a;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgb(22 121 20);
    padding: 8px 12px;
    margin: 8px;}
  
  .airtimeOptions:hover {
    background-color: #e0e0e0;
  }


.form-number .number-input{
    /* border: none !important; */
    width: 90%;
    border: 0.5px solid rgb(0, 0, 0);
    margin-left: 27px !important;
    border-radius: 2px !important;}
    .fixe-in{
       padding-right: var(--bs-gutter-x, .2rem); 
    padding-left: var(--bs-gutter-x, .2rem);
    }


.datalogo img{
width: 50px;
height: 40px;
margin: 30px;
}

.top-logos img{
  width: 50px;
  height: 40px;
}