/* Global styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
  }
  
 
  
  
  .caption {
    font-size: 24px;
    color: #333333;
  }
  
  .divider {
    height: 2px;
    background-color: #bdbdbd;
    margin-bottom: 20px;
  }
  
  .card-panel {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card-panel h6 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 18px;
    color: #333333;
  }
  
  .card-panel img {
    width: 32px;
    height: 32px;
  }
  
  .card-panel:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease;
  }
  
  .tvitem{
    border-radius: 14px;
    padding: 5px;
    margin-left: 3%;
    font-weight: 500;
    margin-top: 4%;
    --tw-shadow: 0px 0px 56px rgba(69, 69, 69, .2);
    --tw-shadow-colored: 0px 0px 56px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  }
  
  

  /* Form container */
.tvform {
    margin: 20px auto;
    max-width: 400px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  
  /* Form styling */
  .tvform form {
    display: flex;
    flex-direction: column;
  }
  .tvform input{
    margin-bottom: 40px

  }
  .tvform select{
    color: #070c07;
    margin-bottom: 40px

  }
  
  .tvform select,
  .tvform input[type="text"],input[type="number"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
    width: 100%;
    margin-left: 0px;

  }
  
  .tvform input[type="text"]::placeholder {
    color: #999;
  }
  
  /* .tvform select {
    background-color: #f9f9f9;
  } */
  
  .tvform input[type="text"] {
    background-color: #f9f9f9;
    margin-bottom: 20px
  }
  
  /* Styling for input labels */
  .tvform label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Button styling */
  .tvform button {
    padding: 10px 20px;
    background-color: #070c07;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

 
  
  .tvform button:hover {
    background-color: #45a049;
  }
  
  /* Optional: Adjustments for responsiveness */
  @media screen and (max-width: 480px) {
    .tvform {
      max-width: 100%;
    }
    .tvitem{
        max-width: 100%;
        position: relative;
    }
  }
  

  
  .tvform select option {
    position: relative;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #cb2f2f;
    border: 1px solid #ccc;
    color:#070c07;
    border-top: none;
    padding: 0;
    margin: 0;
    z-index: 1;
  }
  
  .ft {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-top: 60px; 
    width: 100%;
    text-align: center; /* Adjust text alignment as needed */
  }

  .tvsets{
    position: relative;
  }

  .tv-logo img{
    width: 100px;
    height: 100px;
  }
  .tv-logo{
    align-items: center;
    text-align: center;

  }

  @media screen and (min-width: 890px) {
    .ft {
display: none;   
}
  }