


/* Card panel styling */
.card-panel {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

/* Card styling */
.card {
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: translateY(-5px);
}

/* Image styling */
.card img {
    width: 100px; /* Adjust size as needed */
    height: auto;
    display: block;
    margin: 0 auto;
}

/* Text styling */
.card h6 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Tooltip styling (if applicable) */
.tooltipped {
    position: relative;
}

.tooltipped::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.tooltipped:hover::before {
    opacity: 1;
}
