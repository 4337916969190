.news{
    justify-content: center;
    margin: 5px;
    padding: 5px;
    container-name: news;
    border-color:var(--primary) ;

}


.news .alert{
    border-radius: 20px;
    width: 100%;
    /* border-color:var(--primary) ; */

}


@media (max-width:542px) {
    .news{
      max-width: 100%;
    }
    .transactions{
      max-width: 100%;
    }
    
  }