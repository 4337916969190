/* .error404{
    position: fixed;
    bottom: 50%;
    margin-left: 40%;
    font-weight: 900;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.header404{
    font-size: 55px;

} */


.error404 {
    text-align: center;
    margin-top: 250px; /* Adjust as needed */
    font-family: 'Garamond', serif; /* Fancy font family */
  }
  
  .header404 {
    font-size: 4rem;
    color: #FF5733; /* Adjust color as desired */
  }
  
  .err404btn {
    padding: 5px 20px;
    font-size: 1.2rem;
    background-color: brown;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #FF8C6A; /* Lighter shade on hover */
  }
  