/* src/DownloadButton.css */
.download-ios {
    background-color: #7cc10d;
    border: none;
    color: white;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .download-android {
    background-color: black;
    border: none;
    color: white;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
  }

  .download-button:hover {
    background-color: #45a049;
  }
  
  .devices{
    margin-top: 0%;
  }
  .dex-class{
    margin-top: 15%;
  }