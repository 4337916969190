.account{
    max-width: 400px;
    margin: auto;
}

.bal{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}
.bonus{
    display: block;
    margin-top: -7px;
    padding-left: 15px;
    color: rgb(36, 78, 203);}


.convert{
        margin-top: 117px;
        margin-bottom: 70px;
}
.convertform input{
margin-left: 3%;
width: 100%;
margin-top: 20px;
}
.convertform button{
    margin-left: 0%;
}

.refcode{
    width: 350px;
    display: inline;
    margin: 0;
}
.convertbtn{
    height: 20px;
    width: 50px;
    padding-left: 3px;
    padding-right: 5px;
    padding-top: 3px;
    padding: 0px;
    background-color: white;
    display: block;
    margin-left: 5px;
    margin-top: 10px;
}

.acc-items{

}
.acc-item{
    padding-left: 0px;
   
}
.acc-item p{
    color:goldenrod

}