  /*--
Author: W3layouts
Author URL: http://w3layouts.com
--*/
:root {
  --theme-color: #1F2323;
  --title-color: #051b35;
  --text-color: #333333;
}

/* reset code */
html {
  scroll-behavior: smooth;
}

body,
html {
  margin: 0;
  padding: 0;
  color: #d55d05;
}

* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.wrapper {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 15%;
}

@media (min-width: 576px) {
  .wrapper {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .wrapper {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .wrapper {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .wrapper {
      max-width: 1140px;
  }
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

button,
input,
select {
  -webkit-appearance: none;
  outline: none;
}

button,
.btn,
select {
  cursor: pointer;
}

a {
  text-decoration: none;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

.theme-button {
  min-width: 130px;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #fff;
  background-color: #d55d02;
  padding: 14px 18px;
  border-radius: 4px;
  transition: 0.3s ease;
  border: 1px solid #d55d02;
}

.theme-button:hover {
  background: #010020;
  color: white;
  border: 1px solid #010020;

}
.theme-btn {
min-width: 130px;
cursor: pointer;
text-decoration: none;
-webkit-appearance: none;
display: inline-block;
font-style: normal;
font-weight: normal;
font-size: 15px;
text-align: center;
color: #fff;
background-color: #010020;
padding: 14px 18px;
border-radius: 4px;
transition: 0.3s ease;
border: 1px solid #010020;
}

.theme-btn:hover {
background:  #d55d02;
color: white;
border: 1px solid #d55d02;

}
.form-36-mian {
  background-size: cover;
  background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url("../../assets/images/paysit.png") ;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: grid;
  align-items: center;
  padding: 2rem 0;
}

.form-inner-cont {
  max-width: 450px;
  margin-right: auto;
  background: #fff;
  padding: 3rem;
  border-radius: 8px;
}

.form-inner-cont h3 {
  font-size: 25px;
  line-height: 30px;
  color: #010020;
}
.form-inner-cont img {
width: 40%;
margin-left: 30%;
margin-bottom: 20px;
}
.form-input span.fa {
  color: #010020;
  font-size: 14px;
  border-right: 1px solid #eee;
  margin-right: 5px;
}

.form-input input[type="text"],
.form-input input[type="email"],
.form-input input[type="tel"],
.form-input input[type="password"] {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 0px 12px 0px 0px;
  color: #039;
  height: 45px;
  -webkit-appearance: none;
}

.form-input {
  margin-top: 20px;
  background: rgba(31, 35, 35, 0.03);
  display: grid;
  grid-template-columns: .1fr 1fr;
  align-items: center;
  padding-left: 15px;
  border: 1px solid rgba(31, 94, 180, 0.22);
  border-radius: 4px;
  padding-right: 30px;
}

.login-remember {
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.checkbox {
  width: 16px;
  height: 16px;
  color: #F5F9FC;
  background: #304659;
  float: left;
}

p.remember {
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
}

.new-signup a {
  font-size: 14px;
  margin-top: 25px;
  display: block;
  color: #039;
}
.new-signup a:hover {
color: #d55d02;
}
.social-icons {
  align-items: center;
  border-top: 1px solid #eee;
  padding: 30px 0px 20px;
  margin-top: 40px;
  position: relative;
}

.continue {
  text-align: center;
  display: inline-block;
  width: 14%;
  position: absolute;
  top: -20px;
  left: 43%;
}

.continue span {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  background: #f7f7f7;
  color: #039;
  text-align: center;
  display: inline-block;
  width: 40px;
  border-radius: 50%;
  height: 40px;
  line-height: 40px;
}

button.btn {
  line-height: 40px;
  padding: 0;
  min-width: 100px;
}

.facebook,
.google {
  font-weight: bold;
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 0 10px;
  border-radius: 50%;
}

.facebook span,
.google span {
  display: block;
  line-height: 35px;
  color: #fff;
  font-size: 14px;
}

.facebook {
  background: #3b5998;
}

.google {
  background: #dd4b39;
}

.facebook:hover,
.google:hover {
  opacity: 0.9;
}

p.signup {
  color: var(--text-color);
  font-size: 15px;
  line-height: 25px;
  display: block;
  margin-top: 20px;
}

p.signup a {
  font-weight: bold;
  color: var(--theme-color);
}

p.signup a:hover {
  text-decoration: underline;
}

.social-login {
  display: flex;
  justify-content: center;
}

/* The container */
.check-remaind {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-remaind input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #E1E9F0;
}

/* On mouse-over, add a grey background color */
.check-remaind:hover input~.checkmark {
  background-color: #E1E9F0;
}

/* When the checkbox is checked, add a blue background */
.check-remaind input:checked~.checkmark {
  background-color: #010020
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-remaind input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-remaind .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.copy-right {
  margin-top: 25px;
}

.copy-right p {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}

.copy-right p a {
  color: #fff;
  font-size: 16px;
  text-decoration: underline;
}

.copy-right p a:hover {
  text-decoration: none;
}

.phone-field {
  font-size: 20px;
}

.phone-input {
  text-align: center;
  font-size: 16px !important;
  height: 1.75em;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 2px solid #ddd;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: opacity 0.5s 0.0s ease;
  opacity: 0;
}
/*--responsive--*/

@media(max-width:414px) {
  .social-icons {
      margin-top: 30px;
  }
}


.verify{
  margin-top: 90px;
margin-bottom: 300px;
}


.verification-container {
  max-width: 600px;
  margin: 50px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.support{
  color: blue;
  font-size: x-small;
}
.verifybtn{
width: 80%;
margin-left: 20px;
color: white;
border-color: rgba(255, 255, 255, 0.1);
background: var(--primary-color)
}
.desc-txt{
  margin: 30px;
}
.verification-container  {
  color: #333;
}

.verification-container p {
  line-height: 1.5;
}

.support-text{
  font-size: xx-small;
  margin-top: 20px;
  margin-bottom: 0px;
}
.txt-email{
  color: black;
  font-weight: 400;
}
.phoneerror{
  margin-left:70px;
  color:brown;
  font-size: xx-small;
  padding-top: 20px;
}
.pd-btn{
  padding-left: 5px;
  padding-right: 5px;
}

.emailsuccess{
  font-weight: 900;
  font-size: x-large;
}
/*--//responsive--*/