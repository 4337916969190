
  .serviceblock{
    display: flex;
    width: 100%;
  }

  .cards {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 0px;
    text-align: center;
    border-radius: 1rem;
    --tw-shadow: 0px 0px 56px rgba(69, 69, 69, .2);
    --tw-shadow-colored: 0px 0px 56px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
   
  }
  @media(min-width:900px){
    .card{
      max-width: 80% ;
      margin: 0px ;
      padding-left: 0px;
    }
    .main-ft {
      width: 100%;
      padding: 0px;
      margin-left: 20px;
      height: 250px;
      

    }
    .service{
      margin-left: 0px;
      border-radius: 2rem;
      background-color: white;
      --tw-shadow: 0px 0px 56px rgba(69, 69, 69, .2);
      --tw-shadow-colored: 0px 0px 56px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    }
  }

  @media(max-width: 400px) {
   .serviceblock .col{
      padding-left: 2px;
      margin-left: 21px;
    }
    .serviceblock .main-ft{
      padding-left: 30px;
    }
    .serviceblock .card{
      margin-left: 10px;
    }
    .serviceblock.card2{
      margin-left: 20px;
    }
  }
    @media(max-width: 991px) {
      .card{
    grid-template-columns: 1fr;
    max-width: 100%;
   
   }
   .card2{
    height: 363px;
   }
   .withdraw-btn,
   .deposit-btn{
    max-width: 100%;
   }
  }
  .top-label {
    text-align: left;
    padding: 0 !important;
    margin-bottom: 0px;
    font-size: 14px;
    color: #fff;
    font-weight: 800;
    line-height: 60px;
    margin-top: -18px;
    font-family: 'Dancing Script', cursive;
    margin-left: 0px;

    
  }
  .top-wallet {
    margin-bottom: 0px;
    font-size: 8px;
    color: #fff;
    font-family: 'Dancing Script', cursive;

    
  }
  
  .card {
    border: 0;
    width: 100%;
    margin-inline: auto;
   
  }
  
  .card2{
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100%;
    width: 100%;
    margin: 18px;
    width: 300px;
    height: 250px;
    margin-left: 10px;
    padding: 5px;
    align-items: center;
    text-align: center;
  
  }
  .contents {
    position: relative;
    border: 2px solid ;
    background: linear-gradient(71deg, rgb(4, 99, 47), rgb(0, 0, 0), rgb(4, 99, 47));
    background-clip: padding-box;
    border-radius: 18px;
    padding: 20px;
    width: 100%;
    margin-left: 3%;
  
  }
  .card2contents{
    position: relative;
    background-clip: padding-box;
    border-radius: 75px;
    width: 100%;
    margin-left: 3%;
    font-weight: 900;
    margin-top: 4%;
    --tw-shadow: 0px 0px 56px rgba(69, 69, 69, .2);
    --tw-shadow-colored: 0px 0px 56px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  }

  .card2contents img{
    height:auto ;
    width: 100%;
    transform: scale(1.3); /* Scale the image by a factor of 1.5 (adjust as needed) */
    margin-right: 5px;
  }
  
  
  
  .main-balance {
    font-weight: 400;
    color: white;
    letter-spacing: -0.02em;
    line-height: 40px;
    font-style: normal;
    font-size: 30px;
    padding-bottom: 57px;
  }
  .balance {
    font-weight: 300;
    color: white;
    letter-spacing: -0.02em;
    line-height: 40px;
    font-style: normal;
    font-size: 30px;
    padding-bottom: 15px;
  }
  .main-ft {
    font-weight: 600;
    line-height: 32px;
    color: hsla(0, 0%, 100%, 0.5);
    font-size: 16px;
    max-width: 470px;
    display: flex;
  }

  .service{
    background-color: white;
    border-radius: 15px;
    color:black;
    text-align: center;
    font-weight: bold;
    padding:3px;
    width: 110px;
    margin-left: 21px;
    margin-bottom: 7px;
    max-height: 70px;
    border-radius: 1rem;
    border: 1px solid brown;
    --tw-shadow: 0px 0px 56px rgba(239, 124, 124, 0.2);
    --tw-shadow-colored: 0px 0px 56px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #ff5f5f00),var(--tw-ring-shadow, rgba(243, 23, 23, 0)0000),var(--tw-shadow);
  }

  @media(max-width: 800px) {
    .service{
      margin-left: 10px;
    }
    
  }
  @media (min-width:800px){
    .contents{
      background: linear-gradient(71deg, rgb(4, 99, 47), rgb(0, 0, 0), rgb(4, 99, 47));
      max-width: 500px;

    }
  }
  @media(min-width:23px) and ( max-width: 400px) {
    .service{
      margin-left: 10px;
    }
  }
  .service button{
    background-color: none;
  }

  .service-name{
    font-weight:100;
  }
  .withdraw-btn {
    background-color: brown;
    border-radius: 10px;
    color:black;
    padding:5px;
  }
  

.service-icon svg{
  font-size: 28px;
  color:  silver;

}

.custom-link {
  
  flex-shrink: initial; 
  width: auto; 
  max-width: none; 
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 0px;
}
