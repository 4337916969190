body {
    font-size: 14px;
    font-family: "Moderat","Inter",sans-serif;
    font-weight: 400;
    color: #333;
}

#start-payment-button {
    cursor: pointer;
    position: relative;
    color: white;
    font-weight: 500;
    max-width: 40%;
    padding: 7.5px 16px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    transition: all .1s ease-in;
    vertical-align: middle;
}

.paymentform{
    height: 400px;
    padding: 0px;
    margin-top: 60px;
    margin-left: 0px;
    text-align: center;
    overflow-x: auto;
}

.light-blue-text,b {
    font-size: 12px;
}
.txt1{
    padding: 0px;
    margin: 0px;
    font-size: smaller;
    color:#ff9b00
}