
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

.datacard {
    width: 100%; /* Each card covers 20% of the screen width */
    margin: 3%; /* Add some margin between cards */
    display: inline-block;
    border: 1px solid #000000;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 8px;
    font-family: 'Roboto', sans-serif; /* Apply Roboto font family */

}

.top-data-card {
    /* background-color: #f1f1f1; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.center-card {
    text-align: left;
}

.bottom-card {
    text-align: center;
    
}

.card-amount {
    font-weight:700;
    font-size: 22px;

}

.btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.data-amount {
    font-size: 27px;
    font-weight:400;
    padding-left: 0px;
    margin: 0px;
    padding: 6px;
}

.data-desc{    
    font-size: 12px;
    padding-bottom: 10px;
    text-align: center;
    padding: 0px;
    margin-left: 7px;

}
.airtel_cg {
    font-weight: 500;
    font-size: large;
    color:brown
}
.mtn {
    font-weight: 500;
    font-size: large;
    color:rgb(253, 213, 14)
}
.glo {
    font-weight: 500;
    font-size: large;
    color:rgb(0, 128, 66)
}
.etisalate {
    font-weight: 500;
    font-size: large;
    color:rgb(83, 42, 165)
}
.data-network {
    font-weight: 500;
    font-size: large;
    color:brown
}
.card-btn{
    margin-left: 30px;
    float: right;
    text-align: right;
}