body{
 


}
.aside {
    width: 1200px !important;
    padding: 0 !important;
    margin-right: auto;
    margin-left: auto;
    display: flex;
  
    @media screen and (min-width: 992px) and (max-width: 1439px) {
      max-width: 1279px !important;
      padding: 0 !important;
      margin: 0 80px !important;
      width: auto !important;
    }
  
    @media screen and (max-width: 991px) {
      max-width: 959px !important;
      margin: 0 16px !important;
      padding: 0 !important;
      width: auto !important;
    }
  }

  
  @media (max-width:542px) {
    .update{
      max-width: 100%;
      margin-top: 60px;
    }
    .transactions{
      max-width: 100%;
    }
    
  }

  

  
.whatsapbtn{
  display: table;
   font-family: sans-serif; 
   text-decoration: none; margin: 1em auto; 
  color: #fff; 
  font-size: 0.9em; padding: 1em 2em 1em 3.5em; border-radius: 2em; font-weight: bold; background: #25d366 url('https://tochat.be/click-to-chat-directory/css/whatsapp.svg') no-repeat 1.5em center; 
  background-size: 1.6em;
}